import WebFormRepository from '@/shared/http/repositories/socialProject/web-form'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewWebForm from '@/views/tools/web-form/components/webForm/webForm.vue'
import WebFormModel from '@/shared/models/webForm'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'EditarFormWeb',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewWebForm.name]: NewWebForm
  },

  data: () => ({
    placeholder: 'Nome',
    title: 'Formulário WEB',
    webFormModel: new WebFormModel(),
    isNew: false
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Formulários Web',
            href: this.$router.resolve({ name: 'web-form' }).href
          },
          {
            text: 'Editar formulário WEB',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getById()
  },

  methods: {
    getById() {
      loading.push()
      WebFormRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.webFormModel = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar formulário web', 'ERRO')
        })
    },

    update(webFormModel) {
      loading.push()
      WebFormRepository.Update(webFormModel)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Formulário WEB editado com sucesso', 'EDITAR FORM WEB')
          Promise.resolve()
          return this.$router.replace({ name: 'web-form' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar formulário web', 'ERRO')
        })
    }
  }
}
